import { twMerge } from "tailwind-merge";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  disable?: boolean;
  size?: "sm" | "md" | "lg";
}

const sizeMap = {
  sm: "h-2 md:h-4 lg:h-8",
  md: "h-4 md:h-8 lg:h-16",
  lg: "h-8 md:h-16 lg:h-32",
};

export const Pad: React.FC<Props> = ({
  disable = false,
  size = "md",
  className,
  ...props
}) => {
  return (
    <div {...props} className={twMerge(!disable && sizeMap[size], className)} />
  );
};
export default Pad;
